<template src="./Hall.html"></template>

<script>

import Vue from 'vue';
import VueKonva from 'vue-konva';
import {mapGetters} from "vuex";

Vue.use(VueKonva);


export default {

  name: "Hall",

  data() {
    return {

      scrolled:false,

      image: null,

      // options
      type:"adm", // adm, kassa, user

      // adm[fake, base=sp, pm, am, sm, api, event]
      // kassa []
      // user[]
      mode:"base",

      // armour free sale monger error
      view: "free",

      options:{
        zone:false,
        discount:false,
        armour:false,
        puskin:false
      },


      additional:{
        zone_n:0,                     // какую ценовую зону подкрашивать

        // пока никак не отловлю это - надо хук ставить и на предыдущий state ><
        //seatCanSelectChange: false,   // меняем ли мы возможность выделять места без полной перезагрузки зала

        monger_mode: "show_all",      // show_and_select_only
        monger_id:0,                  // какого уполномоченного выделить; 0 = any
        seat_status_id:0,              // с какими статусами

        // режим, когда во всем зале мы делаем доступными для
        // выделения только кресла переданные в seats_ids
        only_specified_seats: false,
        seats_ids:[],

      },

      layout_id: null,
      layout_pm: null,
      layout_am: null,

      event_id: 2,
      mo_id: 0,

      // end options





      ready: false,
      loaded:false,

      lastCenter: null,
      lastDist: 0,
      cache: false,
      scale:1,
      isShift      : false,

      isInstant:false,

      seats_raw:{},
      event_raw:{},

      // мышечное выделение
      select_rect:{
        x: 0,
        y: 0,
        width: 10,
        height:10,
        fill: 'rgba(255,62,62,0.5)',
        shadowBlur:0,
        stroke: 'red',
      },

      // mouse down on seat
      draw_instant:{
        x:0,
        y:0,
        width:0,
        height:0,
        //fill: 'rgba(255,0,0,1)',
        fillLinearGradientStartPoint: { x: -50, y: -50 },
        fillLinearGradientEndPoint: { x: 50, y: 50 },
        fillLinearGradientColorStops: [0, 'red', 1, 'yellow']
      },


      // массив мест со стандартными полями (придумать стандарт =))
      seats:[],

      // вид одного кресла
      seat:{

        // свойства конвы
        property:{
          type:'seat',
          x: 0,
          y: 0,
          width: 30,
          height:30,
          fill: 'blue',
          shadowBlur:10,
        },

        base:{
          id:         0,
          x:          0,
          y:          0,
          sector:     0,
          row:        0,
          chair:      0,
        },

        flag:{
          free:       false,
          selected:   false,
          hover:      false,
          canSelect:  false,
          scanned:    false,
        },

        additional:{
          price_zone: 0,
        },

        text:{

        }


      },


      configKonva: {
        width:  1600,
        height: 500,
        draggable: true,
        hitOnDragEnabled: true
      },

      // na
      configBG: {
        x: 0,
        y: 0,
        width: 1600,
        height:500,
        fill: 'white',
      }
    };
  },

  computed:{
    ...mapGetters(["getColorZones", "getColorMain", "getEnvoy"]),

    // getOnlyActualMongers(){
    //
    //   let mongers_id = Object.keys(this.event.stat.mongers);
    //   return this.getMongerlist.filter(monger=>mongers_id.includes(monger.id.toString()));
    // },


  },

  created() {

    const image = new window.Image();
    image.src = "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/hall/none.jpg";
    image.onload = () => {
      // set image only when it is loaded
      this.image = image;
    };


    console.log('HALL has been created!');

    this.generateFakeSeats();
    this.ready = true;
    this.loaded = true;

    //this.prepareHall();
    this.$nextTick(() => {
      this.$emit('ImReady');
    });


  },

  mounted(){

    window.addEventListener('mouseup', this.canvas_mouse_up);
    window.addEventListener('touchend', this.canvas_mouse_up);

    window.addEventListener('touchmove', this.touchmove1);
    window.addEventListener('touchend', this.touchend1);

    //this.full_redraw();

  },

  destroyed() {

    window.removeEventListener('mouseup', this.canvas_mouse_up);
    window.removeEventListener('touchend', this.canvas_mouse_up);

    window.removeEventListener('touchmove', this.touchmove1);
    window.removeEventListener('touchend', this.touchend1);

  },


  methods: {

    // без изменения режима работы с админ/касса/пользователь
    // просто меняем отображение
    updateMode(data){
      //console.log('update2');
      this.clearSelected();
      this.updateModeVar(data);
    },

    prepareHall(data){

      //console.log(this.getColorZones);

      //console.log(data);

      this.clearVar(data);

      if ((this.type==='adm')&&(this.mode==='fake')){
        this.generateFakeSeats();
        this.ready = true;
        this.loaded = true;
      }

      let pr = null;

      // для работы с геометрией и раздачей зон/рядов/мест
      if ((this.type==='adm')&&((this.mode==='base')||(this.mode==='sp'))){
        pr = this.$store.state.api.loadLayout_SP(this.layout_id);

        pr.then( data =>{

          console.log(data);
          // проверять в будет ли вообще картинка приходить в таком режиме
          this.image.src = "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/" + data.layout.background;
          this.image.onload = () => {
            this.$forceUpdate();
          };

          this.seats_raw = {
            b:true,
            data: data
          }
          // только геометрия
          this.prepareSeats_SP(this.seats_raw.data.seat_positions);
        })
      }

      // price map
      if ((this.type==='adm')&&(this.mode==='pm')){
        pr = this.$store.state.api.loadLayout_PM(this.layout_id, this.layout_pm);


        pr.then( data =>{

          console.log(data);
          // проверять в будет ли вообще картинка приходить в таком режиме
          this.image.src = "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/" + data.layout.background;
          this.image.onload = () => {};

          this.seats_raw = {
            b:true,
            data: data
          }
          // ценовые зоны
          this.prepareSeats_PM(this.seats_raw.data.seat_pz);
        })
      }

      // price map
      if ((this.type==='adm')&&(this.mode==='am')){

        pr = this.$store.state.api.loadLayout_AM(this.layout_id, this.layout_am);

        pr.then( data =>{

          console.log(data);
          // проверять в будет ли вообще картинка приходить в таком режиме
          this.image.src = "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/" + data.layout.background;
          this.image.onload = () => {};

          this.seats_raw = {
            b:true,
            data: data
          }
          // ценовые зоны
          this.prepareSeats_AM(this.seats_raw.data.seat_pz);
        })
      }

      // Admin Event
      if ((this.type==='adm')&&(this.mode==='event')){
        pr = this.$store.state.api.getEventAdm(this.event_id);

        pr.then( data =>{

          //console.log(data); - в событиях event - будет
          this.image.src = "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/" + data.layout.background;
          this.image.onload = () => {};

          this.event_raw = {
            b:true,
            data: data
          }
          this.prepareSeats_EventAdm(this.event_raw.data.seats);
          this.$emit("ImLoaded", this.event_raw);
        })
      }



      // Kassa Event
      if ((this.type==='kassa')&&(this.mode==='event')){
        pr = this.$store.state.api.getEventKassa(this.event_id);



        pr.then( data =>{

          //console.log(data);
          this.image.src = "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/" + data.layout.background;
          this.image.onload = () => {};


          this.event_raw = {
            b:true,
            data: data
          }
          this.prepareSeats_EventKassa(this.event_raw.data.seats);
          this.$emit("ImLoaded", this.event_raw);
        })
      }

      // user Event
      if ((this.type==='user')&&(this.mode==='event')){
        pr = this.$store.state.api.getEventInet(this.event_id, this.mo_id);

        pr.then( data =>{

          //console.log(data);
          this.image.src = "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/" + data.layout.background;
          this.image.onload = () => {
            this.$nextTick(() => {
              this.full_redraw();
            })
          };


          this.event_raw = {
            b:true,
            data: data
          }
          this.prepareSeats_EventInet(this.event_raw.data.seats);
          this.$emit("ImLoaded", this.event_raw);
        })
      }

      // не успевает
      //this.full_redraw();

    },

    clearSelected(){

      this.seats.forEach(seat=>seat.flag.selected=false);

      this.$nextTick(() => {
        this.full_redraw();
        this.$nextTick(() => {
          this.full_redraw();
        })
      })


    },

    addScannedSeatHall(id) {
      //console.log(id);
      //console.log(this.seats);

      let seat_id = this.seats.findIndex(seat=>seat.base.id===id);
      //console.log(seat_id);

      if (seat_id>0){

        //console.log('dsfsdffdfsdf');
        //console.log(this.seats);
        //console.log(seat_id);


        this.seats[seat_id].flag.scanned = true;

        //console.log(this.seats[seat_id]);

        this.$nextTick(() => {
          this.full_redraw();
          this.$nextTick(() => {
            this.full_redraw();
          })
        })

      }

    },

    clearScanned(){

      this.seats.forEach(seat=>seat.flag.scanned=false);

      this.$nextTick(() => {
        this.full_redraw();
        this.$nextTick(() => {
          this.full_redraw();
        })
      })


    },

    getSelected(only_id){

      //let tt = this.seats.filter(seat=>seat.flag.canSelect);
      //console.log(tt);

      let t = this.seats.filter(seat=>seat.flag.selected);
      console.log(t);

      if (only_id){
        let ids = [];
        t.forEach(seat=>ids.push(seat.base.id));
        console.log(ids);
        return ids;
      }
      else{
        return t;
      }

    },


    getEventInfo(){

     // console.log(this.event_raw);
      return this.event_raw.data.event;

    },




    getDistance(p1, p2) {
      return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    },

    getCenter(p1, p2) {
      return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
      }
    },

    touchend1() {
      this.lastDist = 0;
      this.lastCenter = null;
    },

    touchmove1(e) {


      let stage = this.$refs.stage.getStage();

      let touch1 = e.touches[0];
      let touch2 = null;

      if (e.touches.length>1){
        touch2 = e.touches[1];
      }

      if (touch1 && touch2) {
        if (stage.isDragging()) {
          stage.stopDrag();
        }

        let p1 = {
          x: touch1.clientX,
          y: touch1.clientY,
        };
        let p2 = {
          x: touch2.clientX,
          y: touch2.clientY,
        };

        if (!this.lastCenter) {
          this.lastCenter = this.getCenter(p1, p2);
          return;
        }
        let newCenter = this.getCenter(p1, p2);

        let dist = this.getDistance(p1, p2);

        if (!this.lastDist) {
          this.lastDist = dist;
        }

        let pointTo = {
          x: (newCenter.x - stage.x()) / stage.scaleX(),
          y: (newCenter.y - stage.y()) / stage.scaleX(),
        };

        this.scale = stage.scaleX() * (dist / this.lastDist);

        stage.scaleX(this.scale);
        stage.scaleY(this.scale);

        let dx = newCenter.x - this.lastCenter.x;
        let dy = newCenter.y - this.lastCenter.y;

        let newPos = {
          x: newCenter.x - pointTo.x * this.scale + dx,
          y: newCenter.y - pointTo.y * this.scale + dy,
        };

        stage.position(newPos);

        this.lastDist = dist;
        this.lastCenter = newCenter;

      }
    },

    seat_mouse_click(sid){
      console.log('click '+sid);


      if (this.seats[sid].flag.canSelect){
        this.seats[sid].flag.selected = !this.seats[sid].flag.selected;

        //this.$emit('select_seat', );
        this.$forceUpdate
      }

    },

    seat_mouse_enter(sid){
      this.seats[sid].flag.hover = true;
      this.seats[sid].property.fill = '#b3defd';

    },

    seat_mouse_leave(sid){
      this.seats[sid].flag.hover = false;
      this.full_redraw();
    },

    prepareSeats_Event_AdmMonger(){

      console.log(this.additional.monger_id)

      this.seats.forEach((seat)=>{

        if (
            (seat.additional.monger===this.additional.monger_id)&&
            (seat.additional.status===this.additional.seat_status_id)
        ){
          seat.flag.canSelect = true;
          console.log('can');
        }
        else{
          seat.flag.canSelect = false;
        }


      })

    },


    prepareSeats_EventAdm_Sale(){

      //console.log("!!!");
      //console.log(this.seats);

      this.seats.forEach((seat)=>{

        switch (seat.additional.status){

          case 6:  // бронь на кассе для человека
          case 7:  //бронь (а подробнее будет видно по опции резерв)?
          case 9:  // Бронь администратора
          case 10: // ошибка при продаже абонемент наложился на продажу в мероприятии
          case 11: // абонемент
          case 12: // касса нал
          case 13: // безнал
          case 14: // кассир.ру
          case 15: // инет
          case 16: // счет
          case 34: // техническая бронь на кассе
          case 85: // Кассир.ру бронь
          case 87: // ЛБ бронь
          case 91: // уполномоченные невыданы
          case 92: // выданы
          case 95: // групповая ссылка
          case 96: // групповая ссылка отказ

            seat.flag.canSelect = true;
            break;

          default:
            seat.flag.canSelect = false;
        }

      })

    },


    prepareSeats_EventAdm_Refund(){

      //console.log("!!!");
      //console.log(this.seats);

      this.seats.forEach((seat)=>{

        switch (seat.additional.status){

          case 15:
          case 12:
          case 13:
            seat.flag.canSelect = true;
            break;

          default:
            seat.flag.canSelect = false;
        }

      })

    },


    prepareSeats_Event_Adm_OnlySpecified(){

      console.log("!!!");
      console.log(this.additional.seats);

      this.seats.forEach((seat)=>{

        seat.flag.canSelect = false;

        this.additional.seats.forEach(include_seat_id=>{

          if (seat.base.id===include_seat_id){
            seat.flag.canSelect = true;
            console.log('can');
            return false;
          }

        })

      })

    },



    updateModeVar(data){

      this.view       = data.view;
      this.options    = data.options;
      this.additional = data.additional;

      //console.log(data.additional);

      //console.log(data);


      if (this.additional.monger_mode==="show_and_select_only"){
        console.log('show_select only')
        this.prepareSeats_Event_AdmMonger();
      }
      // получается, что при каждом update переназначаем - место для оптимизации
      else{
        this.prepareSeats_EventAdm(this.event_raw.data.seats);
      }

      if (this.additional.only_specified_seats){
        console.log('only_specified_seats only')
        this.prepareSeats_Event_Adm_OnlySpecified();
      }

      if (this.view==="sale"){
        console.log('higlight_sold_seats')
        this.prepareSeats_EventAdm_Sale();
      }


      // только места, которые можно вернуть - пока это статусы 15 12 13
      if (this.view==="refund"){
        console.log('only_refund_seats')
        this.prepareSeats_EventAdm_Refund();
      }






      this.$nextTick(() => {
        this.full_redraw();
        this.$nextTick(() => {
          this.full_redraw();
        })
      })


    },


    clearVar(data){

      //console.log(data);

      if (data.view.length>1){
        this.view = data.view;
      }

      this.options = data.options;

      this.additional = data.additional;


      if (data.event_id>0){
        this.event_id = data.event_id;
      }

      if (data.mo_id>0){
        this.mo_id = data.mo_id;
      }


      if (data.layout_id>0){
        this.layout_id = data.layout_id;

      }

      if (data.layout_pm>0){
        this.layout_pm = data.layout_pm;
      }

      if (data.layout_am>0){
        this.layout_am = data.layout_am;
      }

      let stage = this.$refs.stage.getStage();

      if (data.scale>0){
        stage.scaleX(data.scale);
        stage.scaleY(data.scale);
      }

      //console.log('1');

      if (data.type.length>0){
        this.type = data.type;
      }
      //console.log('2');

      if (data.mode.length>0){
        this.mode = data.mode;
      }

      //console.log('3');

      stage.position({x:0,y:0});

      this.loaded = false;

    },

    preventDefault(e) {
      e = e || window.event;
      if (e.preventDefault)
        e.preventDefault();
      e.returnValue = false;
    },

    canvas_mouse_wheel(event){

      this.preventDefault();

      if (event.evt.wheelDelta>0){
        this.scale +=0.05;
        //this.scale +=0.05;
      }
      else{
        this.scale -=0.05;
        //this.scale -=0.05;
      }


      this.$refs.stage.getStage().scale({
        x:this.scale,
        y:this.scale
      });


    },

    canvas_mouse_up(){

      if (this.isShift){

        //выделяем кресла
        this.seats.forEach(seat=>this.seatCanSelect(seat));
      }



      this.configKonva.draggable = true;
      this.isShift = false;
      this.isInstant = false;

      if (this.ready){
        this.full_redraw();
      }

    },




    canvas_mouse_down(event){

      if (event.evt.shiftKey){
        this.isShift = true;

        let stage = this.$refs.stage.getStage();

        this.select_rect.x = event.evt.offsetX/this.scale - stage.x()/this.scale;
        this.select_rect.y = event.evt.offsetY/this.scale - stage.y()/this.scale;

        this.configKonva.draggable = false;

      }
      else{
        if (event.target.attrs.type==='seat'){



          let i = this.seats.findIndex(seat=>seat.base.id===event.target.attrs.seat_id);

          if (event.evt.ctrlKey){
            console.log(this.seats[i]);
          }

          if (this.seats[i].flag.canSelect){
            this.seats[i].flag.selected = !this.seats[i].flag.selected;

            //console.log(this.seats[i].base.id, this.seats[i].flag.selected);
            this.$emit('seatClicked', this.seats[i].base.id, this.seats[i].flag.selected, this.seats[i], event);

            this.draw_instant ={
              x:this.seats[i].base.x,
              y:this.seats[i].base.y,
              width:this.seats[i].property.width,
              height:this.seats[i].property.height,
              fillLinearGradientStartPoint: { x: -50, y: -50 },
              fillLinearGradientEndPoint: { x: 50, y: 50 },
              fillLinearGradientColorStops: [0, 'orange', 1, 'red']
              //fill: 'rgba(255,0,0,1)'
            }

            this.isInstant = true;


            this.full_redraw();
          }
        }
      }

    },

    canvas_mouse_move(event){

      //console.log(event);

      if (this.isShift){

        let stage = this.$refs.stage.getStage();

        this.select_rect.width  = event.evt.offsetX/this.scale - this.select_rect.x - stage.x()/this.scale;
        this.select_rect.height = event.evt.offsetY/this.scale - this.select_rect.y - stage.y()/this.scale;
      }

    },

    seatCanSelect(seat){

      let r1x1 = this.select_rect.x;
      let r1x2 = r1x1 + this.select_rect.width;
      let r1y1 = this.select_rect.y;
      let r1y2 = r1y1 + this.select_rect.height;

      // определяем при выделении снизу ввех и слева-направо
      if (r1x2<r1x1){
        let t = r1x1
        r1x1 = r1x2;
        r1x2 = t;
      }
      if (r1y2<r1y1){
        let t = r1y1
        r1y1 = r1y2;
        r1y2 = t;
      }

      let r2x1 = seat.property.x;
      let r2x2 = r2x1 + seat.property.width;
      let r2y1 = seat.property.y;
      let r2y2 = r2y1 + seat.property.height;


      if (seat.flag.canSelect &&
          this.haveIntersection2(
              r1x1,   r1x2,
              r1y1,   r1y2,
              r2x1,   r2x2,
              r2y1,   r2y2
          )){
        seat.flag.selected = true;
        //seat.property.fill = 'gray';
        seat.property.fillLinearGradientColorStops = [0, 'gray', 1, 'yellow']

        //console.log('aa');
      }

    },

    inRect(x,y, rectX1, rectY1, rectX2, rectY2){

      let beginX  = rectX1;
      let beginY  = rectY1;
      let endX    = rectX2;
      let endY    = rectY2;

      return  (x > beginX) &&
          (x < endX) &&
          (y > beginY) &&
          (y < endY);

    },





    haveIntersection2(r1x1, r1x2, r1y1, r1y2, r2x1, r2x2, r2y1, r2y2) {

      return !(
          r2x1 > r1x2 ||
          r2x2 < r1x1 ||
          r2y1 > r1y2 ||
          r2y2 < r1y1
      );
    },

    draw_adm_sp(){

      this.seats.forEach(function (seat) {

        seat.property.fillLinearGradientColorStops = [0, 'red', 1, 'yellow']

        if (!seat.flag.free) {
          //seat.property.fill = "#ff1149";
          seat.property.fillLinearGradientColorStops = [0, 'red', 1, 'red']
        }

        if (seat.flag.canSelect) {
          //seat.property.fill = "#7fd003";
          seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'yellow']
        }

        if (seat.flag.hover) {
          //seat.property.fill = "#b3defd";
        }

        //--

        if (seat.flag.selected) {
          //seat.property.fill = "#ffa810";
          //seat.property.fillLinearGradientColorStops = [0, 'orange', 1, 'yellow']
          seat.property.fillLinearGradientColorStops = [0, this.getColorMain.selected['1'], 1, this.getColorMain.selected['2']]
        }

      }, this)

    },

    draw_adm_pm(){

      this.seats.forEach(function (seat) {

        switch (seat.additional.price_zone){
          case 0:
            seat.property.fillLinearGradientColorStops = [0, 'black', 1, 'white']
            break;

          case 1:
            seat.property.fillLinearGradientColorStops = [0, 'red', 1, 'white']
            break;

          case 2:
            seat.property.fillLinearGradientColorStops = [0, 'blue', 1, 'white']
            break;

          case 3:
            seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'yellow']
            break;

          case 4:
            seat.property.fillLinearGradientColorStops = [0, 'white', 1, 'purple']
            break;

          case 5:
            seat.property.fillLinearGradientColorStops = [0, 'white', 1, 'orange']
            break;

          case 6:
            seat.property.fillLinearGradientColorStops = [0, 'red', 1, 'orange']
            break;

          default:
            seat.property.fillLinearGradientColorStops = [0, 'black', 1, 'blue']

        }
        //--

        if (seat.flag.selected) {
          //seat.property.fill = "#ffa810";
          //seat.property.fillLinearGradientColorStops = [0, 'orange', 1, 'yellow']
          seat.property.fillLinearGradientColorStops = [0, this.getColorMain.selected['1'], 1, this.getColorMain.selected['2']]
        }

      }, this)

    },

    draw_adm_am(){

      this.seats.forEach(function (seat) {

        seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'yellow']

        if (seat.additional.armour_general===3) {
          //seat.property.fill = "#ff1149";
          seat.property.fillLinearGradientColorStops = [0, 'red', 1, 'white']
        }

        //--

        if (seat.flag.selected) {
          //seat.property.fill = "#ffa810";
          //seat.property.fillLinearGradientColorStops = [0, 'orange', 1, 'yellow']
          seat.property.fillLinearGradientColorStops = [0, this.getColorMain.selected['1'], 1, this.getColorMain.selected['2']]
        }

      }, this)

    },

    option_draw_event_price_zones(seat){

      //console.log(this.additional);

      // отрисовываем все ценовые зоны
      if (this.additional.zone_n===0){

        let color1 = this.getColorZones[seat.additional.price_zone][2];
        let color2 = this.getColorZones[seat.additional.price_zone][3];

        seat.property.fillLinearGradientColorStops = [0, color1, 1, color2];

      }
      else{
        // только переданную
        if (this.additional.zone_n===seat.additional.price_zone){

          let color1 = this.getColorZones[seat.additional.price_zone][2];
          let color2 = this.getColorZones[seat.additional.price_zone][3];

          seat.property.fillLinearGradientColorStops = [0, color1, 1, color2];

        }
        // все остальные затемняем
        else{

          seat.property.fillLinearGradientColorStops = [0, this.getColorMain.block['1'], 1, this.getColorMain.block['2']]

        }
      }







      return seat;

    },

    view_draw_event_sale(seat){

      switch (seat.additional.status){

          // ошибка при продаже абонемент наложился на продажу в мероприятии
        case 10:
          seat.property.fillLinearGradientColorStops = [0.8, '#ff2ee3', 0.6, '#00dabe']
          break;

        // абонемент
        case 11:

          //seat.property.fillLinearGradientColorStops = [0.8, '#1d7eab', 0.6, '#00dabd']
          seat.property.fillLinearGradientColorStops = [0, '#1d7eab', 1, '#00dabd']
          break;

          // инет
        case 15:
          seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'lime']
          break;

        // касса нал
        case 12:
          seat.property.fillLinearGradientColorStops = [0, '#cbf1ff', 1, '#77aaff']
          break;
        // безнал
        case 13:
          seat.property.fillLinearGradientColorStops = [0, '#39a9ff', 1, '#397dff']
          break;
        // счет
        case 16:
          seat.property.fillLinearGradientColorStops = [0, '#39a9ff', 1, '#bb84ff']
          break;

        // кассир.ру
        case 14:
          seat.property.fillLinearGradientColorStops = [0, '#ff9f39', 1, '#f8d211']
          break;

          // уполномоченные
         // невыданы
        case 91:
          seat.property.fillLinearGradientColorStops = [0, '#e087ff', 1, '#e087ff']
          break;
        // выданы
        case 92:
          seat.property.fillLinearGradientColorStops = [0, '#f2d7ff', 1, '#f1cbff']
          break;

          // групповая ссылка
        case 95:
          seat.property.fillLinearGradientColorStops = [0, '#e4b1ff', 1, '#ff9cce']
          break;

          // групповая ссылка отказ
        case 96:
          seat.property.fillLinearGradientColorStops = [0, '#f2d7ff', 1, '#ff1078']
          break;

          //бронь (а подробнее будет видно по опции резерв)?
        case 7:
          seat.property.fillLinearGradientColorStops = [0, this.getColorMain.fired['1'], 1, this.getColorMain.fired['2']]
          break;

          // бронь на кассе для человека
        case 6:
          seat.property.fillLinearGradientColorStops = [0, '#ffffff', 1, '#ffffff']
          break;

        // техническая бронь на кассе
        case 34:
          seat.property.fillLinearGradientColorStops = [0, '#ff0000', 1, '#ff0000']
          break;

        // Кассир.ру бронь
        case 85:
          seat.property.fillLinearGradientColorStops = [0, '#8bff5b', 1, '#ffdf06']
          break;

        // ЛБ бронь
        case 87:
          seat.property.fillLinearGradientColorStops = [0, '#f9d1ff', 1, '#ffdf06']
          break;

        // Бронь администратора
        case 9:
          seat.property.fillLinearGradientColorStops = [0, '#dcdcdc', 1, '#d0d0d0']
          break;



        default:
          seat.property.fillLinearGradientColorStops = [0, this.getColorMain.block['1'], 1, this.getColorMain.block['2']]
      }

      return seat;

    },

    view_draw_event_refund(seat){

      switch (seat.additional.status){

          // инет
        case 15:
          seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'lime']
          break;

          // касса нал
        case 12:
          seat.property.fillLinearGradientColorStops = [0, '#cbf1ff', 1, '#77aaff']
          break;
          // безнал
        case 13:
          seat.property.fillLinearGradientColorStops = [0, '#39a9ff', 1, '#397dff']
          break;

        default:
          seat.property.fillLinearGradientColorStops = [0, this.getColorMain.block['1'], 1, this.getColorMain.block['2']]
      }

      return seat;

    },



    view_draw_event_user_sale(seat){

      switch (seat.additional.status){

        // проданные
        //case 25:
          //seat.property.fillLinearGradientColorStops = [0, this.getColorMain.block['1'], 1, this.getColorMain.block['2']]
          //seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'lime']
          //break;

        // свободные
        case 25:
          seat.property.fillLinearGradientColorStops = [0, '#39a9ff', 1, '#1166f8']
          break;
      }

      return seat;

    },


    view_draw_event_scaner(seat){

      //console.log(this.additional);

      seat.property.fillLinearGradientColorStops = [0, this.getColorMain.block['1'], 1, this.getColorMain.block['2']]

      // let color1 = "#c9d0ff";
      // let color2 = "#cfe0ff";

      let color1 = "#c9d0ff";
      let color2 = "#cfe0ff";

      let scanned1 = "#ff8d00";
      let scanned2 = "#ffffff";


      // только уполномоченных статусы
      switch (seat.additional.status){

          // уполномоченные
          // придумать нецветовые различия для невыданных/оформленных/просто бронь
        case 12:
        case 13:
        case 14:
        case 15:
        case 92:


          seat.property.fillLinearGradientColorStops = [0, color1, 1, color2];

          break;

        default:
          if (seat.flag.free){
            //seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'white']
            seat.property.fillLinearGradientColorStops = [0, this.getColorMain.free['1'], 1, this.getColorMain.free['2']]
          }

      }

      if (seat.flag.scanned){
        //console.log(seat.base.id);
        seat.property.fillLinearGradientColorStops = [0, scanned1, 1, scanned2];

      }







      return seat;

    },



    view_draw_event_monger(seat){

      //console.log(this.additional);

      seat.property.fillLinearGradientColorStops = [0, this.getColorMain.block['1'], 1, this.getColorMain.block['2']]

      let color1 = "#e087ff";
      let color2 = "#e087ff";

      let monger_f = {};

      if (this.additional.monger_mode==="show_all"){

        // только уполномоченных статусы
        switch (seat.additional.status){

            // уполномоченные
            // придумать нецветовые различия для невыданных/оформленных/просто бронь
          case 91:
          case 92:


            //console.log(seat.additional.monger);
            this.getEnvoy.find(env=>env.id===seat.additional.monger)

            if (seat.additional.monger>0){

              monger_f = this.getEnvoy.find(env=>env.id===seat.additional.monger);

              color1 = monger_f.color1;
              color2 = monger_f.color2;
            }

            seat.property.fillLinearGradientColorStops = [0, color1, 1, color2];


            // switch (seat.additional.monger){
            //   // тут персональный цвет для каждого уполномоченного
            //   case 14:
            //     seat.property.fillLinearGradientColorStops = [0, '#e087ff', 1, '#ff8787'];
            //     break;
            //
            //   case 8:
            //     seat.property.fillLinearGradientColorStops = [0, '#e087ff', 1, '#ffe203']
            //     break;
            //
            // }


            break;

          default:
            if (seat.flag.free){
              //seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'white']
              seat.property.fillLinearGradientColorStops = [0, this.getColorMain.free['1'], 1, this.getColorMain.free['2']]
            }

        }

      } //show_all

      if (this.additional.monger_mode==="show_and_select_only"){

        if (
            (seat.additional.monger===this.additional.monger_id)&&
            (seat.additional.status===this.additional.seat_status_id)
        ){

          color1 = "#4be7e7";
          color2 = "#4be7e7";

          seat.property.fillLinearGradientColorStops = [0, color1, 1, color2];
        }
        // темним все остальные
        else{
          seat.property.fillLinearGradientColorStops = [0, this.getColorMain.block['1'], 1, this.getColorMain.block['2']]
        }

      }








      return seat;

    },


    view_draw_event_seat(seat){

      //console.log(this.additional);

      seat.property.fillLinearGradientColorStops = [0, this.getColorMain.block['1'], 1, this.getColorMain.block['2']]

      let color1 = "#4be7e7";
      let color2 = "#4be7e7";

      if (this.additional.only_specified_seats){

        if (seat.flag.canSelect){
          seat.property.fillLinearGradientColorStops = [0, color1, 1, color2];
        }
        // темним все остальные
        else{
          seat.property.fillLinearGradientColorStops = [0, this.getColorMain.block['1'], 1, this.getColorMain.block['2']]
        }

      }

      return seat;

    },

    view_draw_event_free(seat){

      if (seat.flag.free){
        //seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'white']
        seat.property.fillLinearGradientColorStops = [0, this.getColorMain.free['1'], 1, this.getColorMain.free['2']]
      }
      else{
        seat.property.fillLinearGradientColorStops = [0, this.getColorMain.block['1'], 1, this.getColorMain.block['2']]
      }

      return seat;

    },

    view_draw_event_armour(seat){

      //seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'lime']

      if (seat.additional.status===2){
        seat.property.fillLinearGradientColorStops = [0, this.getColorMain.block['1'], 1, this.getColorMain.block['2']]
      }

      return seat;

    },

    draw_user_event(){

      //console.log(this.seats);

      this.seats.forEach(function (seat) {


        // режим отображения для админа
        switch (this.view){

          case 'sale':
            this.view_draw_event_user_sale(seat);
            if (seat.flag.free) {
              if (this.options.zone) {
                this.option_draw_event_price_zones(seat);
              }
            }
            break;

          case 'group':
            break;




          default:
        }

        // бронь раскладки - всегда рисуем
        this.view_draw_event_armour(seat);

        //seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'lime']

        // если бронь раскладки, то никак не красим
        // if (seat.additional.status===2){
        //   seat.property.fillLinearGradientColorStops = [0, 'white', 1, 'gray']
        //   return;
        // }

        // если кресло свободно, то красим в ценовую зону
        // if (seat.flag.free){
        // if (this.options.zone){
        //   seat = this.option_draw_event_price_zones(seat);
        // }
        //
        // }





        if (seat.flag.selected) {
          //seat.property.fill = "#ffa810";
          //seat.property.fillLinearGradientColorStops = [0, 'orange', 1, 'yellow']
          seat.property.fillLinearGradientColorStops = [0, this.getColorMain.selected['1'], 1, this.getColorMain.selected['2']]
        }

      }, this)

    },


    draw_adm_event(){

      //console.log(this.seats);

      this.seats.forEach(function (seat) {


        // режим отображения для админа
        switch (this.view){

            // бронь раскладки
          case 'armour':
            //seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'white']
            seat.property.fillLinearGradientColorStops = [0, this.getColorMain.free['1'], 1, this.getColorMain.free['2']]
            // ценовые зоны
            if (this.options.zone){
              this.option_draw_event_price_zones(seat);
            }
            break;
          case 'free':
            this.view_draw_event_free(seat);
            if (seat.flag.free) {
              if (this.options.zone) {
                this.option_draw_event_price_zones(seat);
              }
            }
            break;
          case 'sale':
            this.view_draw_event_sale(seat);
            // if (seat.flag.free) {
            //   if (this.options.zone) {
            //     this.option_draw_event_price_zones(seat);
            //   }
            // }
            break;

            // посдветить места доступные для возврата
          case 'refund':

            this.view_draw_event_refund(seat);
            // if (seat.flag.free) {
            //   if (this.options.zone) {
            //     this.option_draw_event_price_zones(seat);
            //   }
            // }
            break;



          case 'monger':
            this.view_draw_event_monger(seat);

            if (seat.flag.free) {
              // не отрисовывать свободные места, когда нам надо подсветить только определенные
              if (this.additional.monger_mode!=="show_and_select_only"){
                if (this.options.zone) {
                  this.option_draw_event_price_zones(seat);
                }
              }

            }
            break;

          // подсвечивать только переданные места при подготовке
          case 'seats':
             this.view_draw_event_seat(seat);
            break

          case 'scaner':

            this.view_draw_event_scaner(seat);

            break;


          case 'error':
            break;

          default:
        }

        // бронь раскладки - всегда рисуем
        this.view_draw_event_armour(seat);

        //seat.property.fillLinearGradientColorStops = [0, 'green', 1, 'lime']

        // если бронь раскладки, то никак не красим
        // if (seat.additional.status===2){
        //   seat.property.fillLinearGradientColorStops = [0, 'white', 1, 'gray']
        //   return;
        // }

        // если кресло свободно, то красим в ценовую зону
        // if (seat.flag.free){
        // if (this.options.zone){
        //   seat = this.option_draw_event_price_zones(seat);
        // }
        //
        // }





        if (seat.flag.selected) {
          //seat.property.fill = "#ffa810";
          //seat.property.fillLinearGradientColorStops = [0, 'orange', 1, 'yellow']
          seat.property.fillLinearGradientColorStops = [0, this.getColorMain.selected['1'], 1, this.getColorMain.selected['2']]
        }

      }, this)

    },

    draw_adm_xx(){

      this.seats.forEach(function (seat) {

        //--

        if (seat.flag.selected) {
          //seat.property.fill = "#ffa810";
          //seat.property.fillLinearGradientColorStops = [0, 'orange', 1, 'yellow']
          seat.property.fillLinearGradientColorStops = [0, this.getColorMain.selected['1'], 1, this.getColorMain.selected['2']]
        }

      }, this)

    },

    full_redraw(){

      //console.log('1'+this.loaded);

      if (this.loaded) {


        this.cache = false;
        this.$refs.group.getNode().clearCache();

        this.$nextTick(() => {

          switch (this.mode) {
            case "sp":
            case "base":
              this.draw_adm_sp();
              break;

            case "pm":
              this.draw_adm_pm();
              break;

            case "am":
              this.draw_adm_am();
              break;

            case "event":

              if (this.type==='user'){
                this.draw_user_event()
              }
              else(
                this.draw_adm_event()
              )


              break;
          }

          this.cache = true;
          this.$refs.group.getNode().cache();
        })

      }
    },

    prepareSeats_SP(api_seats){

      this.seats = [];
      //console.log(api_seats);

      for (let key in api_seats){

        let a_seat = api_seats[key];

        let seat = {

          property:{
            type: 'seat',
            seat_id: a_seat.id,
            x: a_seat.left,
            y: a_seat.top,
            width: 30,
            height:30,
            // fill: 'blue',
            shadowBlur:0,
            stroke:'black',
            //text: '1',
            fillLinearGradientStartPoint: { x: -50, y: -50 },
            fillLinearGradientEndPoint: { x: 50, y: 50 },
            fillLinearGradientColorStops: [0, 'blue', 1, 'yellow']
          },

          base:{
            id:         a_seat.id,
            x:          a_seat.left,
            y:          a_seat.top,
            sector:     a_seat.sector_id,
            row:        a_seat.row,
            chair:      a_seat.chair,
          },

          flag:{
            free:       true,
            selected:   false,
            hover:      false,
            //canSelect:  a_seat.isFree,
            canSelect:  true,
            scanned:    false,
          },

          additional:{
            price_zone: a_seat.zone,
          },

          text:{
            type: 'seat',
            seat_id: a_seat.id,
            x: a_seat.left,
            y: a_seat.top+7,
            text: a_seat.chair,
            fontSize: 18,
            fontFamily: 'Calibri',
            fill: '#555',
            width: 30,
            padding: 0,
            align: 'center',
            show: true,
          }


        };

        this.seats.push(seat);

      }

      this.ready = true;
      this.loaded = true;

      this.$nextTick(() => {
        this.full_redraw();

        this.$nextTick(() => {
          this.full_redraw();

        })
      })



    },


    prepareSeats_PM(api_seats){

      //console.log(api_seats);

      this.seats = [];
      //console.log(api_seats);

      for (let key in api_seats){

        let a_seat = api_seats[key];

        let seat = {

          property:{
            type: 'seat',
            seat_id: a_seat.id,
            x: a_seat.left,
            y: a_seat.top,
            width: 30,
            height:30,
            // fill: 'blue',
            shadowBlur:0,
            stroke:'black',
            //text: '1',
            fillLinearGradientStartPoint: { x: -50, y: -50 },
            fillLinearGradientEndPoint: { x: 50, y: 50 },
            fillLinearGradientColorStops: [0, 'blue', 1, 'yellow']
          },

          base:{
            id:         a_seat.id,
            x:          a_seat.left,
            y:          a_seat.top,
            sector:     a_seat.sector_id,
            row:        a_seat.row,
            chair:      a_seat.chair,
          },

          flag:{
            free:       true,
            selected:   false,
            hover:      false,
            //canSelect:  a_seat.isFree,
            canSelect:  true,
            scanned:    false,
          },

          additional:{
            price_zone: a_seat.zone,
          },

          text:{
            type: 'seat',
            seat_id: a_seat.id,
            x: a_seat.left,
            y: a_seat.top+7,
            text: a_seat.chair,
            fontSize: 18,
            fontFamily: 'Calibri',
            fill: '#555',
            width: 30,
            padding: 0,
            align: 'center',
            show: true,
          }
        };

        this.seats.push(seat);

      }

      this.ready = true;
      this.loaded = true;

      this.$nextTick(() => {
        this.full_redraw();

        this.$nextTick(() => {
          this.full_redraw();

        })
      })

    },


    prepareSeats_AM(api_seats){

      //console.log(api_seats);

      this.seats = [];
      //console.log(api_seats);

      for (let key in api_seats){

        let a_seat = api_seats[key];

        let seat = {

          property:{
            type: 'seat',
            seat_id: a_seat.id,
            x: a_seat.left,
            y: a_seat.top,
            width: 30,
            height:30,
            // fill: 'blue',
            shadowBlur:0,
            stroke:'black',
            //text: '1',
            fillLinearGradientStartPoint: { x: -50, y: -50 },
            fillLinearGradientEndPoint: { x: 50, y: 50 },
            fillLinearGradientColorStops: [0, 'blue', 1, 'yellow']
          },

          base:{
            id:         a_seat.id,
            x:          a_seat.left,
            y:          a_seat.top,
            sector:     a_seat.sector_id,
            row:        a_seat.row,
            chair:      a_seat.chair,
          },

          flag:{
            free:       true,
            selected:   false,
            hover:      false,
            //canSelect:  a_seat.isFree,
            canSelect:  true,
            scanned:    false,
          },

          additional:{
            price_zone: a_seat.zone,
            armour_general: a_seat.status_general,
            armour_inet: a_seat.status_inet,
            armour_kassa: a_seat.status_kassa,
            armour_monger: a_seat.status_monger,
            armour_remote: a_seat.status_remote

          },

          text:{
            type: 'seat',
            seat_id: a_seat.id,
            x: a_seat.left,
            y: a_seat.top+7,
            text: a_seat.chair,
            fontSize: 18,
            fontFamily: 'Calibri',
            fill: '#555',
            width: 30,
            padding: 0,
            align: 'center',
            show: true,
          }
        };

        this.seats.push(seat);

      }

      this.ready = true;
      this.loaded = true;

      this.$nextTick(() => {
        this.full_redraw();

        this.$nextTick(() => {
          this.full_redraw();

        })
      })

    },





    prepareSeats_EventAdm(api_seats){

      //console.log(api_seats);

      this.seats = [];
      //console.log(api_seats);

      for (let key in api_seats){

        let a_seat = api_seats[key];

        let seat = {

          property:{
            type: 'seat',
            seat_id: a_seat.id,
            x: a_seat.left,
            y: a_seat.top,
            width: 30,
            height:30,
            // fill: 'blue',
            shadowBlur:0,
            stroke:'black',
            //text: '1',
            fillLinearGradientStartPoint: { x: -50, y: -50 },
            fillLinearGradientEndPoint: { x: 50, y: 50 },
            //fillLinearGradientColorStops: [0, 'green', 1, 'white']
            fillLinearGradientColorStops : [0, this.getColorMain.free['1'], 1, this.getColorMain.free['2']]
          },

          base:{
            id:         a_seat.id,
            x:          a_seat.left,
            y:          a_seat.top,
            sector:     a_seat.sector_id,
            row:        a_seat.row,
            chair:      a_seat.chair,
          },

          flag:{
            free:       a_seat.isFree,
            selected:   false,
            hover:      false,
            canSelect:  a_seat.isFree,
            scanned:    false,
          },

          additional:{

            status: a_seat.status,
            //status_group: a_seat.status_group,

            price_zone: a_seat.zone,
            discount: 0,
            session: 0,

            user: a_seat.user,
            mo: a_seat.mo,
            monger: a_seat.monger,
            payment: a_seat.payment,

          },

          text:{
            type: 'seat',
            seat_id: a_seat.id,
            x: a_seat.left,
            y: a_seat.top+7,
            text: a_seat.chair,
            fontSize: 18,
            fontFamily: 'Calibri',
            fill: '#555',
            width: 30,
            padding: 0,
            align: 'center',
            show: true,
          }
        };

        this.seats.push(seat);

      }

      //console.log(this.seats);

      this.ready = true;
      this.loaded = true;

      this.$nextTick(() => {
        this.full_redraw();

        this.$nextTick(() => {
          this.full_redraw();

        })
      })

    },


    prepareSeats_EventInet(api_seats){

      //console.log(api_seats);

      this.seats = [];
      //console.log(api_seats);

      // бронь
      let seat_status = 2;

      for (let key in api_seats){

        let a_seat = api_seats[key];

        if (a_seat.isFree){
          seat_status = 25;
        }
        else {
          seat_status = 2;
        }

        let seat = {

          property:{
            type: 'seat',
            seat_id: a_seat.id,
            x: a_seat.left,
            y: a_seat.top,
            width: 30,
            height:30,
            // fill: 'blue',
            shadowBlur:0,
            stroke:'black',
            //text: '1',
            fillLinearGradientStartPoint: { x: -50, y: -50 },
            fillLinearGradientEndPoint: { x: 50, y: 50 },
            fillLinearGradientColorStops: [0, 'black', 1, 'yellow']
          },

          base:{
            id:         a_seat.id,
            x:          a_seat.left,
            y:          a_seat.top,
            sector:     a_seat.sector_id,
            row:        a_seat.row,
            chair:      a_seat.chair,
          },

          flag:{
            free:       a_seat.isFree,
            selected:   false,
            hover:      false,
            canSelect:  a_seat.isFree,
            scanned:    false,
          },

          additional:{

            // потенциально косяк
            status: seat_status,
            //status_group: a_seat.status_group,

            price_zone: a_seat.zone,
            discount: 0,
            session: 0,

            user: a_seat.user,
            mo: a_seat.mo,
            monger: a_seat.monger,
            payment: a_seat.payment,

          },

          text:{
            type: 'seat',
            seat_id: a_seat.id,
            x: a_seat.left,
            y: a_seat.top+7,
            text: a_seat.chair,
            fontSize: 18,
            fontFamily: 'Calibri',
            fill: '#555',
            width: 30,
            padding: 0,
            align: 'center',
            show: true,
          }
        };

        this.seats.push(seat);

      }

      //console.log(this.seats);

      this.ready = true;
      this.loaded = true;

      this.$nextTick(() => {
        this.full_redraw();

        this.$nextTick(() => {
          this.full_redraw();

        })
      })

    },



    prepareSeats_EventKassa(api_seats){

      console.log(api_seats);

      this.seats = [];
      //console.log(api_seats);

      for (let key in api_seats){

        let a_seat = api_seats[key];

        let seat = {

          property:{
            type: 'seat',
            seat_id: a_seat.id,
            x: a_seat.left,
            y: a_seat.top,
            width: 30,
            height:30,
            // fill: 'blue',
            shadowBlur:0,
            stroke:'black',
            //text: '1',
            fillLinearGradientStartPoint: { x: -50, y: -50 },
            fillLinearGradientEndPoint: { x: 50, y: 50 },
            fillLinearGradientColorStops: [0, 'black', 1, 'yellow']
          },

          base:{
            id:         a_seat.id,
            x:          a_seat.left,
            y:          a_seat.top,
            sector:     a_seat.sector_id,
            row:        a_seat.row,
            chair:      a_seat.chair,
          },

          flag:{
            free:       a_seat.isFree,
            selected:   false,
            hover:      false,
            canSelect:  a_seat.isFree,
            scanned:    false,
          },

          additional:{

            status: a_seat.status,
            //status_group: a_seat.status_group,

            price_zone: a_seat.zone,
            discount: 0,
            session: 0,

            user: a_seat.user,
            mo: a_seat.mo,
            monger: a_seat.monger,
            payment: a_seat.payment,

          },

          text:{
            type: 'seat',
            seat_id: a_seat.id,
            x: a_seat.left,
            y: a_seat.top+7,
            text: a_seat.chair,
            fontSize: 18,
            fontFamily: 'Calibri',
            fill: '#555',
            width: 30,
            padding: 0,
            align: 'center',
            show: true,
          }
        };

        this.seats.push(seat);

      }

      console.log(this.seats);

      this.ready = true;
      this.loaded = true;

      this.$nextTick(() => {
        this.full_redraw();

        this.$nextTick(() => {
          this.full_redraw();

        })
      })

    },



    generateFakeSeats(){

      this.seats = [];


      let xx = 0;
      let yy = 300;


      for (var i = 0; i < 40; i++) {

        let ss = true;

        if ((i==4) || (i==17)){
          ss = false;
        }

        if (i>20){
          yy = 340;
          xx = 40*20;
        }

        let seat = {

          property:{
            seat_id: i,
            x: 40*i-xx+250,
            y: yy,
            width: 30,
            height:30,
            //fill: 'blue',
            shadowBlur:5,
            stroke:'black',


            // id: i,
            numPoints: 5,
            innerRadius: 30,
            outerRadius: 50,
            opacity: 0.8,
            shadowColor: 'black',
            shadowOpacity: 1,
            scaleX: 1,
            scaleY: 1,
            type: 'seat',

            // text: '1',
            fillLinearGradientStartPoint: { x: -50, y: -50 },
            fillLinearGradientEndPoint: { x: 50, y: 50 },
            //fillLinearGradientColorStops: [0, 'black', 1, 'gray']
            fillLinearGradientColorStops : [0, this.getColorMain.block['1'], 1, this.getColorMain.block['2']]
            //fillLinearGradientColorStops: [0, 'blue', 1, 'yellow']
          },

          base:{
            id:         i,
            x:          40*i-xx+250,
            y:          yy,
            sector:     i,
            row:        i,
            chair:      i,
          },

          flag:{
            free:       false,
            selected:   false,
            hover:      false,
            canSelect:  ss
          },

          additional:{
            price_zone: 0,
          },

          text:{
            type: 'seat',
            seat_id: i,
            x: 40*i-xx+250,
            y: yy+7,
            text: i,
            fontSize: 18,
            fontFamily: 'Calibri',
            fill: 'white',
            width: 30,
            padding: 0,
            align: 'center',
            show: true,
          }


        };

        this.seats.push(seat);


      } //for

    },

  }

}


</script>


<style scoped>


</style>
